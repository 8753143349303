<template>
  <div>
    <form-dialog :dialog="formDialog" :formName="dynamicFormName">
      <v-form ref="formFields">
        <v-row>
          <v-col cols="12" md="4" sm="6">
            <custom-label label="Nome"/>
            <simple-text-field v-model="payload.name"
                              required
                              placeholder="Nome"
            />
          </v-col>

          <v-col cols="12" md="4" sm="6">
            <custom-label label="Tamanho do Talhão"/>
            <simple-text-field v-model="payload.size"
                              v-input-decimal-mask="decimalMask"
                              :key="componentKey"
                              class="input-field-size"
                              required
                              placeholder="Tamanho do Talhão"
                              @keydown="$event.key === '-' ? $event.preventDefault() : null"
            />
          </v-col>

          <v-col cols="12" md="4" sm="6">
            <custom-label label="Status"/>
            <simple-select :items="isActiveItems"
                          v-model="payload.is_active"
                          itemText="text"
                          itemValue="value"
                          :menuProps="{ bottom: true, offsetY: true, closeOnClick: true}"
                          required
                          :rules="requiredRules"
            />
          </v-col>
        </v-row>
      </v-form>

      <v-row class="mt-2">
        <v-col>
          <custom-label label="Doenças Identificadas"/>
        </v-col>
      </v-row>

      <v-container class="custom-container custom-scroll">
        <v-row align="center">
          <v-col class="d-flex justify-start">
            <custom-label16
              label="Nome da Doença"
              class="font-weight-bold"
              style="flex: 0.4;"/>
        
            <router-link
              to=""
              style="flex: 0.5;"
              v-model="checkDisease"
              @click.native="checkFieldDiseases"
            >
              <custom-label12 label="Marcar todos"/>
            </router-link>
          </v-col>

          <v-spacer></v-spacer>

          <v-col class="d-flex justify-end">
            <custom-label16
              label="Presença"
              class="font-weight-bold"
              style="flex: 0.4;"/>
          </v-col>
        </v-row>

        <div class="container containerDiseases"
          v-for="disease in diseases"
          :key="disease.id">

          <hr/>

          <v-row class="rowDisease">
            <custom-label14
              :label="disease.name"
              style="flex: 0.9; padding-top: 1.2rem;"/>

            <span>
              <v-checkbox
                v-model="checkboxDiseases[disease.id]"
                color="#CFA545"
                :value="disease.id"
              />
            </span>
          </v-row>
        </div>
      </v-container>
    </form-dialog>
    <v-row align="center">
      <v-col class="d-flex justify-start">
        <custom-label18 label="Talhões da Fazenda"></custom-label18>
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="d-flex justify-end">
        <primary-button
          v-if="authoritySlug === 'super-admin' || permissions.access_producers"
          label="+ Adicionar"
          type="button"
          @callAction="create"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
            :footer-props="{
        'items-per-page-text': 'Linhas por página',
        'items-per-page-all-text': 'Todos',
      }"
            :headers="headers"
            :items="data"
            :loading="fetching"
            loading-text="Carregando dados, por favor aguarde..."
            class="rounded-xl"
            sort-by="id"
            no-data-text="Não foram encontrados talhões."
            no-results-text="Nenhum registro bate com a busca."
        >

          <template v-slot:[`item.id`]="{ item }">
            #{{ item.id }}
          </template>

          <template v-slot:[`item.size`]="{ item }">
            {{ parseFloat(item.size) }} ha
          </template>

          <template v-slot:[`item.diseases`]="{ item }">
            {{ item.diseases.length }}
          </template>

          <template v-slot:[`item.is_active`]="{ item }">
            {{ getStatus(item.is_active) }}
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-icon class="ml-4" small @click="edit(item)">
              mdi-pencil
            </v-icon>

            <v-icon class="ml-4" small @click="deleteItem(item)">
              mdi-trash-can-outline
            </v-icon>
          </template>

        </v-data-table>
      </v-col>

    </v-row>

    <confirm-destroy-dialog
      :title="payload.name"
      :dialog.sync="showConfirmDestroyDialog"
      @closeDialog="closeConfirmDestroyDialog"
    />
  </div>
</template>

<script>
import Fields from "@/domain/fields/fields";
import FormDialog from "@/components/crud/FormDialog";
import SimpleTextField from "@/components/inputs/SimpleTextField.vue";
import SimpleSelect from "@/components/inputs/SimpleSelect.vue";
import CustomLabel from "@/components/texts/CustomLabel";
import CustomLabel12 from "@/components/texts/CustomLabel12";
import CustomLabel14 from "@/components/texts/CustomLabel14";
import CustomLabel16 from "@/components/texts/CustomLabel16";
import CustomLabel18 from "@/components/texts/CustomLabel18.vue";
import PrimaryButton from "@/components/buttons/PrimaryButton.vue";
import ConfirmDestroyDialog from "@/components/dialogs/ConfirmDestroyDialog";

import {getItem} from '@/services/local-storage.service';
import Diseases from "@/domain/diseases/diseases.js";

import { VMoney } from "v-money-no-negative";

export default {
  directives: { inputDecimalMask: VMoney },
  name: "Fields",
  props: ['farm_id'],
  components: {
    FormDialog,
    SimpleTextField,
    SimpleSelect,
    CustomLabel,
    CustomLabel12,
    CustomLabel14,
    CustomLabel16,
    CustomLabel18,
    PrimaryButton,
    ConfirmDestroyDialog
  },
  data() {
    return {
      isActiveItems: [
        {
          text: 'Ativo',
          value: true,
        },
        {
          text: 'Inativo',
          value: false,
        },
      ],

      decimalMask: {
        decimal: ',',
        thousands: '.',
      },

      fetching: true,
      data: [],
      payload: {},
      selected: {},
      diseases: [],
      checkboxDiseases: [],

      fieldService: null,
      diseaseService: null,
      canToggleField: false,

      formDialog: false,
      isEditing: false,
      showConfirmDestroyDialog: false,

      authoritySlug: "",
      permissions: "",
      checkDisease: false,
      componentKey: 0,

      dataFarm: {},

      requiredRules: [v => (!!v || v === false) || "O campo é obrigatório"]
    }
  },
  computed: {
    headers() {
      let headers = [
        {text: "ID", align: "start", value: "id"},
        {text: "Nome do Talhão", value: "name"},
        {text: "Tamanho", value: "size"},
        {text: "Doenças", value: "diseases"},            
        {text: "Status", value: "is_active"},            
      ];

      if (this.authoritySlug === 'super-admin' || this.permissions.access_producers) {
        headers.push(
          {text: "Ações", value: "actions", sortable: false}
        );
      }

      return headers;
    },
    dynamicFormName() {
      if(this.isEditing) {
        return "Editar Talhão";
      }

      return 'Novo Talhão';
    },
  },
  methods: {
    async getFields() {
      this.fetching = true;
      this.data = [];

      await this.fieldService.listByFarm(this.farm_id).then((result) => {
        result.data.map((itm) => {
          this.data.push(itm);
        });
      });

      this.getDataFarm();
      this.$emit('update-component-farm', this.dataFarm);

      this.fetching = false;
    },
    getDiseases() {
      let loader = this.$loading.show();
      this.diseases = [];

      this.diseaseService.list().then(result => {
        result.data.map(item => {
          this.diseases.push(item);
        });
      });
      
      loader.hide();
    },
    getStatus(status) {
      if (status === true) return "Ativo";

      return "Inativo";
    },
    checkFieldDiseases() {
      this.checkDisease = !this.checkDisease;

      this.diseases.map(item => {
        this.checkboxDiseases[item.id] = this.checkDisease ? item.id : null;
      });
    },
    create() {
      this.componentKey += 1;
      this.payload = {};
      this.payload.is_active = true;
      this.checkboxDiseases = [];
      this.selected = {};
      this.canToggleField = false;
      this.formDialog = true;
    },
    edit(item) {
      this.componentKey += 1;

      this.payload = Object.assign({}, item);
      this.selected = Object.assign({}, item);

      this.payload.diseases.map(item => {
        this.checkboxDiseases[item.id] = item.id;
      });

      this.canToggleField = true;
      this.formDialog = true;
      this.isEditing = true;
    },
    deleteItem(item) {
      this.payload = Object.assign({}, item);
      this.showConfirmDestroyDialog = true;
    },
    closeConfirmDestroyDialog() {
      this.showConfirmDestroyDialog = false;
    },
    closeFormDialog() {
      this.payload = {};
      this.selected = {};
      this.checkboxDiseases = [];
      this.formDialog = false;
      this.isEditing = false;
    },
    initialize() {
      this.fieldService   = Fields;
      this.diseaseService = Diseases;
      this.payload        = this.fieldService.newData();

      this.authoritySlug = getItem('authoritySlug');
      this.permissions   = JSON.parse(getItem('permissions'));

      this.getFields();
      this.getDiseases();
    },
    getDataFarm() {
      let sum = 0;

      this.data.forEach(item => sum = sum + parseFloat(item.size));

      this.dataFarm = {
        'number_fields'   : this.data.length,
        'fields_size_sum' : sum
      };
    },
    save() {
      if (!this.$refs.formFields.validate()) {
        return;
      }

      let loader = this.$loading.show();

      this.payload.farm_id  = this.farm_id;
      this.payload.diseases = this.checkboxDiseases;
      this.payload.size     = this.payload.size.replace('.', '').replace(',', '.');

      if (!this.isEditing) {
        this.fieldService.create(this.payload)
            .then(() => {
              loader.hide();
              this.initialize();
              this.closeFormDialog();
              this.$toasted.show("Talhão cadastrado com sucesso.", {
                type: "success",
              });
            })
            .catch(({ response }) => {
              loader.hide();
              const { error } = response.data;
              this.$toasted.show(error, {
                type: "error",
              });
            });
      } else {
        this.fieldService.edit(this.payload)
            .then(() => {
              loader.hide();
              this.getFields();
              this.closeFormDialog();
              this.$toasted.show("Talhão atualizado com sucesso.", {
                type: "success",
              });
            })
            .catch(({ response }) => {
              loader.hide();
              const { error } = response;
              this.$toasted.show(error, {
                type: "error",
              });
            });
      }
    },
    async destroy() {
      const loader = this.$loading.show();

      await this.fieldService.delete(this.payload).then(() => {
        loader.hide();
        this.getFields();
        this.showConfirmDestroyDialog = false;
        this.$toasted.show("Talhão removido com sucesso.", {
          type: "success",
        });
      })
      .catch(() => {
        loader.hide();
        this.showConfirmDestroyDialog = false;
        this.$toasted.show("Talhão não foi removido.", {
          type: 'error'
        });
      });
    },
  },
  mounted() {
    this.initialize();
  }
}
</script>

<style scoped>
  .v-application a {
    color: #3880FF;
  }

  .custom-scroll {
    height: 33rem;
    overflow: auto;
    overflow-x: hidden;
  }

  .custom-container {
    border: 1px solid #e3e3e3;
    border-radius:5px;
    padding: 1.5rem 2rem 0.75rem 2rem;
  }

  .containerDiseases {
    padding: 8px 0 0 0;
    margin-bottom: -12px;
  }

  .rowDisease {
    margin: 0;
  }

  .input-field-size:after {
    content:'HA';
    position: absolute;
    top: 0.9rem;
    left: calc(90% - 5px);
    display: block;
    font-size: 15px;
    color: #212020;
  }
</style>