<template>
  <div>
    <div v-if="openDetails" class="mt-4 font-weight-bold">
      <v-icon @click="closeView()"> mdi-arrow-left </v-icon>&nbsp;
      <span style="margin-left: 0.5rem;">Voltar</span>
    </div>

    <toolbar
      :toolbarName="dynamicToolbarName"
      :openDetails="openDetails"
      :authoritySlug="authoritySlug"
      :permissions="permissions.access_producers"
    />

    <form-dialog :dialog="formDialog" :formName="dynamicFormName">
      <v-toolbar flat v-if="canToggle">
        <v-switch
          v-model="payload.is_active"
          :label="getStatus(payload.is_active)"
          color="red"
          @change="toggleFarm(payload.id)"
          :value="payload.is_active"
          hide-details
        ></v-switch>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-form ref="formFarm">
        <v-row>
          <v-col cols="12" md="4" sm="6">
            <custom-label label="Nome" />
            <simple-text-field
              v-model="payload.name"
              required
              placeholder="Nome"
            />
          </v-col>
          <v-col cols="12" md="4" sm="6">
            <custom-label label="Proprietário" />
            <simple-select
              :items="owners"
              v-model="payload.producer_id"
              itemText="user.name"
              itemValue="producer_id"
              placeholder="Selecione um proprietário"
              :menuProps="{ bottom: true, offsetY: true, closeOnClick: true }"
              required
              :rules="requiredRules"
            />
          </v-col>
        </v-row>

        <v-row :key="componentKey">
          <v-col cols="12" md="4" sm="4">
            <custom-label label="CEP" />
            <simple-text-field
              v-model="payload.zip_code"
              required
              mask="zip"
              placeholder="CEP"
              @custom-key-up="getCep()"
            />
          </v-col>
          <v-col cols="12" md="4" sm="4">
            <custom-label label="Endereço" />
            <simple-text-field
              v-model="payload.address"
              placeholder="Endereço"
              required
            />
          </v-col>
          <v-col cols="12" md="4" sm="4">
            <custom-label label="Complemento" />
            <simple-text-field
              v-model="payload.complement"
              placeholder="Complemento"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="4" sm="6">
            <custom-label label="Cidade" />
            <simple-text-field
              v-model="payload.city"
              placeholder="Cidade"
              required
            />
          </v-col>
          <v-col cols="12" md="4" sm="6">
            <custom-label label="Estado" />
            <simple-select
              :items="states"
              v-model="payload.state_id"
              itemText="name"
              itemValue="id"
              placeholder="Selecione um estado"
              :menuProps="{ bottom: true, offsetY: true, closeOnClick: true }"
              required
              :rules="requiredRules"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="4" sm="6">
            <custom-label label="Região"></custom-label>
            <simple-select
              v-model="payload.region"
              :items="regions"
              itemText="text"
              itemValue="text"
              placeholder="Selecione uma região"
              @custom-change="changeRegion()"
              :menuProps="{
                bottom: true,
                offsetY: true,
                closeOnClick: true,
                maxHeight: 200,
              }"
              required
              :rules="requiredRules"
            >
            </simple-select>
          </v-col>

          <v-col cols="12" md="4" sm="6">
            <custom-label label="Sub-Região"></custom-label>
            <simple-select
              v-model="payload.sub_region"
              :items="subRegionFiltered"
              itemText="text"
              itemValue="text"
              placeholder="Selecione uma sub-região"
              :disabled="!regionSelected"
              :menuProps="{
                bottom: true,
                offsetY: true,
                closeOnClick: true,
                maxHeight: 200,
              }"
              required
              :rules="requiredRules"
            >
            </simple-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="4" sm="6">
            <custom-label label="Mapa da Fazenda" />
            <span class="text-upload">
              <p>Upload</p>
            </span>

            <v-file-input
              v-model="payload.map"
              class="map-file"
              dense
              outlined
              height="48px"
              placeholder="Escolha um arquivo KML"
              accept="application/vnd.google-earth.kml+xml"
              prepend-icon=""
              :rules="fileRules"
            />
          </v-col>
        </v-row>
      </v-form>
    </form-dialog>

    <search-bar
      v-if="!openDetails"
      v-model="search"
      @externalFiltersClear="clearFilters"
      :noSecondaryInput="false"
    >
      <simple-select
        v-model="stateSearch"
        :items="data.map((item) => item.state.uf)"
        @input="filterByState()"
        itemText="name"
        itemValue="name"
        class="mr-2"
        placeholder="Estado"
        height="0"
      />
    </search-bar>

    <div v-if="openDetails">
      <row-details :show="openDetails">
        <v-row align="center">
          <v-col class="d-flex justify-start">
            <custom-label18 label="Informações Gerais"></custom-label18>
          </v-col>
          <v-spacer></v-spacer>
          <v-col class="d-flex justify-end">
            <v-icon
              v-if="
                authoritySlug === 'super-admin' || permissions.access_producers
              "
              @click="edit()"
            >
              mdi-pencil
            </v-icon>
          </v-col>
        </v-row>
        <v-row :key="componentKey">
          <col-details column="3" name="Nome" :item="payload.name" />
          <col-details column="3" name="Endereço" :item="payload.address" />
          <col-details
            column="3"
            name="Complemento"
            :item="payload.complement"
          />
          <col-details
            column="3"
            name="CEP"
            mask="zip"
            :item="payload.zip_code"
          />
          <col-details column="3" name="Cidade" :item="payload.city" />
          <col-details column="3" name="Estado" :item="payload.state.name" />
          <col-details
            column="3"
            name="Proprietário"
            :item="payload.producer.user.name"
          />
          <col-details column="3" name="" :item="null" />
          <col-details column="3" name="Tamanho" :item="farmSize + ' ha'" />
          <col-details
            column="3"
            name="Número de Talhões"
            :item="selected.fields.length"
          />
          <col-details
            column="3"
            name="Mapa"
            :item="payload.file !== null ? payload.file.original_name : ''"
          />
          <col-details column="3" name="" :item="null"></col-details>
          <col-details
            column="3"
            name="Região"
            :item="payload.region !== null ? payload.region : ''"
          />
          <col-details
            column="3"
            name="Sub-Região"
            :item="payload.sub_region !== null ? payload.sub_region : ''"
          />
        </v-row>

        <template v-slot:[`other-row-details`]>
          <fields
            :farm_id="selected.id"
            @update-component-farm="updateFarmSizeAndNumberFields($event)"
          />
        </template>
      </row-details>
    </div>

    <data-table
      :headers="headers"
      :fetching="fetching"
      :data="data"
      :objectName="objectName"
      :search="search.text"
      v-else
    >
      <template v-slot:[`item.is_active`]="{ item }">
        {{ getStatus(item.is_active) }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon class="ml-4" small @click="view(item)">
          mdi-information-outline
        </v-icon>

        <v-icon v-if="authoritySlug === 'super-admin' || permissions.access_producers"
          class="ml-4" small @click="deleteItem(item)">
          mdi-trash-can-outline
        </v-icon>
      </template>
    </data-table>

    <confirm-destroy-dialog
      :title="payload.name"
      :dialog.sync="showConfirmDestroyDialog"
      @closeDialog="closeConfirmDestroyDialog"
    />
  </div>
</template>

<script>
import DataTable from "@/components/data-table/DataTable";
import Toolbar from "@/components/crud/Toolbar";
import FormDialog from "@/components/crud/FormDialog";
import RowDetails from "@/components/crud/Details";
import ColDetails from "@/components/crud/DetailsItem";
import SimpleTextField from "@/components/inputs/SimpleTextField.vue";
import SearchBar from "@/components/layouts/SearchBar.vue";
import SimpleSelect from "@/components/inputs/SimpleSelect.vue";
import CustomLabel from "@/components/texts/CustomLabel";
import Fields from "./Fields";
import Users from "@/domain/users/users";
import Authorities from "@/domain/authorities/authorities";
import States from "@/domain/states/states";
import Farms from "@/domain/farms/farms";
import Producers from "@/domain/producers/producers";
import Ceps from "@/domain/ceps/ceps";
import CustomLabel18 from "@/components/texts/CustomLabel18.vue";
import ConfirmDestroyDialog from "@/components/dialogs/ConfirmDestroyDialog";
import { getItem } from "@/services/local-storage.service";
export default {
  name: "Farms",
  components: {
    DataTable,
    Toolbar,
    FormDialog,
    RowDetails,
    ColDetails,
    SimpleTextField,
    SearchBar,
    SimpleSelect,
    CustomLabel,
    CustomLabel18,
    Fields,
    ConfirmDestroyDialog
  },
  data() {
    return {
      objectName: "fazendas",
      toolbarName: "Lista de Fazendas",
      formName: "Adicionar Propriedade",
      fetching: true,
      search: {},
      stateSearch: "",
      data: [],
      states: [],
      owners: [],
      userService: null,
      authorityService: null,
      stateService: null,
      farmService: null,
      producerService: null,
      cepService: null,
      payload: null,
      selected: null,
      canToggle: false,
      slug: "producer",
      authority_id: 0,
      openDetails: false,
      formDialog: false,
      authoritySlug: "",
      permissions: "",
      componentKey: 0,
      farmSize: 0,
      showConfirmDestroyDialog: false,
      regionSelected: false,
      subRegionFiltered: [],
      regions: [
        { text: "M01" },
        { text: "M02" },
        { text: "M03" },
        { text: "M04" },
        { text: "M05" },
      ],
      subRegions: [
        {
          M01: [{ text: 101 }, { text: 102 }, { text: 103 }, { text: 104 }],
        },
        {
          M02: [{ text: 201 }, { text: 202 }, { text: 203 }, { text: 204 }],
        },
        {
          M03: [{ text: 301 }, { text: 302 }, { text: 303 }, { text: 304 }],
        },
        {
          M04: [
            { text: 401 },
            { text: 402 },
            { text: 403 },
            { text: 404 },
            { text: 405 },
          ],
        },
        {
          M05: [{ text: 501 }, { text: 502 }, { text: 503 }],
        },
      ],
      requiredRules: [(v) => !!v || v === false || "O campo é obrigatório"],
      fileRules: [
        (v) => {
          const fileType = v ? v.name.slice(v.name.lastIndexOf(".")) : null;
          if (!v && this.openDetails) {
            return "O campo é obrigatório";
          } else if (fileType !== ".kml") {
            return "Somente arquivo kml é permitido";
          } else {
            return true;
          }
        },
      ],
    };
  },
  methods: {
    getFarms() {
      this.fetching = true;
      this.data = [];
      this.farmService
        .list()
        .then((result) => {
          result.data.map((itm) => {
            this.data.push(itm);
          });
        })
        .catch(({ response }) => {
          const { error } = response.data;
          this.$toasted.show(error, {
            type: "error",
          });
        });
      this.fetching = false;
    },
    getOwners() {
      let loader = this.$loading.show();
      this.owners = [];
      this.producerService.all().then((result) => {
        result.data.map((itm) => {
          this.owners.push(itm);
        });
      });
      loader.hide();
    },
    getAuthority() {
      let loader = this.$loading.show();
      this.authorityService.getBySlug(this.slug).then((result) => {
        result.data.map((itm) => {
          this.authority_id = itm.id;
        });
      });
      loader.hide();
    },
    getStates() {
      let loader = this.$loading.show();
      this.states = [];
      this.stateService.list().then((result) => {
        result.data.map((itm) => {
          this.states.push(itm);
        });
      });
      loader.hide();
    },
    async getCep() {
      if (this.payload.zip_code.length === 9) {
        let loader = this.$loading.show();
        await this.cepService
          .get(this.payload.zip_code)
          .then((result) => {
            this.payload.address = result.data.logradouro;
            this.payload.city = result.data.localidade;
            const stateSelected = this.states.find(
              (item) => item.uf === result.data.uf
            );

            this.payload.state_id = stateSelected.id;
          })
          .catch(() => {
            loader.hide();
            this.$toasted.show("Cep não encontrado.", {
              type: "error",
            });
          });
        this.componentKey += 1;
        loader.hide();
      }
    },
    getStatus(status) {
      if (status === true) return "Ativo";
      return "Inativo";
    },
    updateFarmSizeAndNumberFields(event) {
      this.selected.fields.length = event.number_fields;
      this.farmSize = parseFloat(event.fields_size_sum.toFixed(2));
      this.componentKey += 1;
    },
    toggleFarm(id) {
      let loader = this.$loading.show();
      this.farmService
        .toggle(id)
        .then(() => {
          loader.hide();
          this.$toasted.show("Status alterado com sucesso.", {
            type: "success",
          });
        })
        .catch(({ response }) => {
          loader.hide();
          const { error } = response.data;
          this.$toasted.show(error, {
            type: "error",
          });
        });
    },
    initialize() {
      this.userService = Users;
      this.authorityService = Authorities;
      this.stateService = States;
      this.farmService = Farms;
      this.producerService = Producers;
      this.cepService = Ceps;
      this.payload = this.farmService.newData();
      this.authoritySlug = getItem("authoritySlug");
      this.permissions = JSON.parse(getItem("permissions"));
      this.getFarms();
      this.getOwners();
      this.getStates();
    },
    view(item) {
      this.payload = Object.assign({}, item);
      this.selected = Object.assign({}, item);
      this.openDetails = true;
    },
    closeDetails() {
      this.payload = {};
      this.selected = {};
      this.openDetails = false;
    },
    create() {
      this.payload = {};
      this.selected = {};
      this.canToggle = false;
      this.formDialog = true;
    },
    edit() {
      this.canToggle = true;
      this.formDialog = true;
      this.filterSubRegion();
      this.payload.map = {};
      this.payload.map.name = this.payload.file.original_name;
    },
    deleteItem(item) {
      this.payload = Object.assign({}, item);
      this.showConfirmDestroyDialog = true;
    },
    closeConfirmDestroyDialog() {
      this.showConfirmDestroyDialog = false;
    },
    closeFormDialog() {
      this.formDialog = false;
    },
    closeView() {
      this.openDetails = false;
      this.initialize();
    },
    changeRegion() {
      this.filterSubRegion();
    },
    filterSubRegion() {
      const region = this.payload.region;
      const subRegion = this.payload.sub_region || "";

      const subRegionSelected = this.subRegions.find((item) => {
        const { [region]: value } = item;
        if (value) return item;
      });
      this.subRegionFiltered = subRegionSelected[[region]];
      this.regionSelected = true;
      this.payload.sub_region = parseInt(subRegion);
    },
    save() {
      if (!this.$refs.formFarm.validate()) {
        return;
      }
      let loader = this.$loading.show();
      this.payload.is_active =
        this.payload.is_active === null ? false : this.payload.is_active;

      if(this.payload.file)
        this.payload.map =
          this.payload.map.name !== this.payload.file.original_name
            ? this.payload.map
            : "";

      this.payload.complement = this.payload.complement || "";

      let formData = new FormData();
      formData.append("name", this.payload.name);
      formData.append("address", this.payload.address);
      formData.append("city", this.payload.city);
      formData.append("zip_code", this.payload.zip_code);
      formData.append("complement", this.payload.complement);
      formData.append("state_id", this.payload.state_id);
      formData.append("producer_id", this.payload.producer_id);
      formData.append("region", this.payload.region);
      formData.append("sub_region", this.payload.sub_region);
      formData.append("map", this.payload.map);

      if (!this.openDetails) {
        formData.append("is_active", true);
        this.farmService
          .create(formData)
          .then(() => {
            loader.hide();
            this.closeFormDialog();
            this.initialize();
            this.$toasted.show("Propriedade cadastrada com sucesso.", {
              type: "success",
            });
          })
          .catch(({ response }) => {
            loader.hide();
            const { error } = response.data;
            this.$toasted.show(error, {
              type: "error",
            });
          });
      } else {
        formData.append("is_active", this.payload.is_active);
        formData.append("_method", "PUT");
        this.farmService
          .edit(this.payload.id, formData)
          .then(() => {
            loader.hide();
            const stateSelected = this.states.find(
              (item) => item.id === this.payload.state_id
            );
            const producerSelected = this.owners.find(
              (item) => item.producer_id === this.payload.producer_id
            );
            this.payload.state.name = stateSelected.name;
            this.payload.producer.user.name = producerSelected.user.name;
            if (this.payload.map) {
              this.payload.file = { original_name: this.payload.map.name };
            }
            this.closeFormDialog();
            this.$toasted.show("Propriedade atualizada com sucesso.", {
              type: "success",
            });
          })
          .catch(({ response }) => {
            loader.hide();
            const { error } = response;
            this.$toasted.show(error, {
              type: "error",
            });
          });
      }
    },
    async destroy() {
      const loader = this.$loading.show();

      await this.farmService.delete(this.payload).then(() => {
        loader.hide();
        this.getFarms();
        this.showConfirmDestroyDialog = false;
        this.$toasted.show("Fazenda removida com sucesso.", {
          type: "success",
        });
      })
      .catch(() => {
        loader.hide();
        this.showConfirmDestroyDialog = false;
        this.$toasted.show("Fazenda não foi removida.", {
          type: 'error'
        });
      });
    },
    /*handleFileUpload(event) {
      this.payload.map = event;
    },*/
    filterByState() {
      this.search.state = this.stateSearch;
    },
    clearFilters() {
      this.search = {};
      this.stateSearch = "";
    },
  },
  computed: {
    headers() {
      return [
        {
          text: "ID",
          align: "start",
          value: "id",
          sortable: this.canBeSortable,
        },
        {
          text: "Nome da Fazenda",
          value: "name",
          sortable: this.canBeSortable,
        },
        {
          text: "Estado",
          value: "state.uf",
          sortable: this.canBeSortable,
          filter: (value) => {
            if (this.stateSearch === "") return true;
            return value === this.search.state;
          },
        },
        {
          text: "Produtor",
          value: "producer.user.name",
          sortable: this.canBeSortable,
        },
        { text: "Detalhes", value: "actions", sortable: false },
      ];
    },
    canBeSortable() {
      return !this.fetching;
    },
    dynamicToolbarName() {
      if (this.openDetails) {
        return "Detalhes da Fazenda / " + this.payload.name;
      }
      return this.toolbarName;
    },
    dynamicFormName() {
      if (this.openDetails) {
        return "Editar Propriedade";
      }
      return this.formName;
    },
    fieldsSizeSum() {
      let sum = 0;
      if (this.selected) {
        this.selected.fields.forEach(function (element) {
          sum = sum + parseFloat(element.size);
        });
      }
      return sum;
    },
  },
  beforeMount() {
    this.initialize();
  },
};
</script>

<style scoped>
.text-upload {
  display: flex;
  top: 48px;
  width: 87px;
  height: 48px;
  padding: 0.7rem 1.2rem;
  background: #e9ecef 0% 0% no-repeat padding-box;
  border: 1px solid #ced4da;
  border-radius: 5px 0px 0px 5px;
}
.text-upload > p {
  text-align: left;
  letter-spacing: 0.11px;
  color: #575757;
}
::v-deep .map-file .v-text-field__slot {
  margin-left: 6rem;
}
.map-file {
  position: relative;
  bottom: 3rem;
}
::v-deep .map-file .v-text-field__slot:hover {
  cursor: pointer;
}
</style>