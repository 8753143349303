import http from "@/services/http.service";

const apiUrl = "/cep";

class CepsService {
  static get(cep) {
    return http.get(`${apiUrl}/${cep}`);
  }
}

export default CepsService;
