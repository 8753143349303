import Ceps from "./ceps.service";

class Cep {
  constructor(payload = {}) {
    Object.assign(this, {
      ...Cep.build(payload),
    });
  }

  static build({
    cep         = "",
    logradouro  = "",
    complemento = "",
    localidade  = "",
    uf          = ""
  }) {
    return {
      cep,
      logradouro,
      complemento,
      localidade,
      uf
    };
  }

  static newData(payload) {
    return new Cep(payload);
  }

  static get(cep) {
    return new Promise((resolve, reject) => {
      Ceps
      .get(cep)
      .then(
        success => {
          const cep = success;
          cep.data = this.newData(cep.data.data);
          resolve(cep);
        },

        error => {
          reject(error);
        }
      );
    });
  }
}

export default Cep;
